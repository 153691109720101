import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  CustomInput,
  Table,
} from "reactstrap";

import firebase from "firebase.config";

export default class Events extends React.Component {
  state = {
    headers: [
      {
        name: "",
        title: "",
        text: "",
        url: "",
        info:"",
        background: ""

      }
    ],
    events: []
  };

  componentDidMount = () => {
    this.initialize();
  };

  initialize = () => {
    console.log("Props:", this.props);
    this.setState({
      events: this.props.events
    });
  };

  addEvent = () => {
    let { name, title, text, url, background, info } = this.state.headers[0];
    if (name.length < 2) {
      alert("Please enter valid title");
      return;
    }
    // if (title.length < 2) {
    //   alert("Please enter valid title")
    //   return
    // }
    if (title.length < 4) {
      alert("Please enter valid URL");
      return;
    }
    if (title.length < 4) {
      alert("Please enter valid background Image URL");
      return;
    }
    firebase
      .database()
      .ref("/groupsData/" + this.props.userDetails.groupPath + "/events")
      .push({
        name,
        title,
        text,
        url,
        background,
        info,

      })
      .then(res => {
        this.setState({
          name: "",
          title: "",
          text: "",
          url: "",
          background: "",
          info:""

        });
      });
  };

  // handleChange = (event, key) => {
  //   this.setState({
  //     [key]: event.target.value
  //   });
  //   console.log("handle change ", key, ":", this.state.headers);
  // };

  handleChange = (event, key) => {
    var temp = this.state.headers;

    if (key === "name") {
      temp[0].name = event.target.value;
    } else if (key === "title") {
      temp[0].title = event.target.value;
    } else if (key === "text") {
      temp[0].text = event.target.value;
    } else if (key === "url") {
      temp[0].url = event.target.value;
    }else if (key === "info") {
      temp[0].info = event.target.value;
    }

    this.setState({
      headers: temp
    });
  };

  deleteEvent = event => {
    let res = window.confirm(
      'Are you sure that you want to delete event "' + event.name + '"'
    );
    if (res) {
      firebase
        .database()
        .ref(
          "/groupsData/" +
            this.props.userDetails.groupPath +
            "/events/" +
            event.key
        )
        .remove();
    }
  };

  addImage = async event => {
    var temp = this.state.headers;
    temp[0].imageTemp = event.target.files[0];

    this.setState({
      headers: temp
    });

    console.log("Image To Be Uploaded!");

    await this.saveImage();
  };

  saveImage = async () => {
    let temp = this.state.headers;
    let file = temp[0].imageTemp;

    if (file) {
      var min = 1;
      var max = 100;
      var rand = min + Math.random() * (max - min);

      var filename = "event" + "+" + rand;

      const storage = firebase.storage().ref();
      const image = storage.child(filename);

      await image.put(file).then(async snapshot => {
        await image.getDownloadURL().then(async url => {
          console.log("Image URL:", url);
          temp[0].background = url;
          delete temp[0].imageTemp;
          this.setState({
            headers: temp
          });
        });
      });
      console.log("Image Uploaded!!!");
    }
  };

  moveUp = index => {
    var temp = this.state.events;
    var temp2 = temp[index - 1];

    temp[index - 1] = temp[index];
    temp[index] = temp2;

    this.setState({
      events: temp
    });

    this.saveToDB();
  };

  moveDown = index => {
    var temp = this.state.events;
    var temp2 = temp[index + 1];

    temp[index + 1] = temp[index];
    temp[index] = temp2;

    this.setState({
      events: temp
    });

    this.saveToDB();
  };

  saveToDB = () => {
    // https://www.youtube.com/
    console.log("/groupsData/" + this.props.userDetails.groupPath + "/events");
    firebase
      .database()
      .ref("/groupsData/" + this.props.userDetails.groupPath + "/events/")
      .remove();

    this.state.events.map((event, index) => {
      var temp = this.state.headers;
      temp[0].name = event.name;
      temp[0].title = event.title;
      temp[0].text = event.text;
      temp[0].url = event.url;
      temp[0].background = event.background;
      temp[0].info = event.info;
      
      console.log("Events:", this.state.events);
      console.log("Save to DB temp:", temp);
      console.log(
        "Querry:",
        "/groupsData/",
        this.props.userDetails.groupPath,
        "/events/"
      );
      this.addEvent();
    });
    // firebase
    //   .database()
    //   .ref("/groupsData/" + this.props.userDetails.groupPath + "/events/")
    //   .push({
    //     events: this.state.events
    //   });
  };

  render() {
    return (
      <>
        <div className="content">
          {!this.props.userDetails.group ? (
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Error</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <p>
                        Dear {this.props.userDetails.name}, before you can add a
                        event please set Unique Identifier for your group from
                        the dashboard.
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">New Event</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>New Event Details: </label>
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex"
                          }}
                        >
                          <Input
                            type="text"
                            placeholder="Enter Event Name"
                            value={this.state.name}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleChange(event, "name");
                            }}
                          />
                          <Input
                            type="text"
                            placeholder="Enter Title Name"
                            value={this.state.title}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleChange(event, "title");
                            }}
                          />
                        </div>
                        <Input
                          type="textarea"
                          placeholder="Enter Brief Info"
                          value={this.state.info}
                          style={{ margin: 5 }}
                          onChange={event => {
                            this.handleChange(event, "info");
                          }}
                        />
                        <Input
                          type="text"
                          placeholder="Enter URL"
                          value={this.state.url}
                          style={{ margin: 5 }}
                          onChange={event => {
                            this.handleChange(event, "url");
                          }}
                        />
                        {/* <Input
                            type="text"
                            placeholder="Enter Background Image URL"
                            value={this.state.background}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleChange(event, "background");
                            }}
                          /> */}
                        <div
                          className="form-control"
                          style={{
                            marginLeft: 5,
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              flex: 1,
                              alignItems: "center"
                            }}
                          >
                            <p style={{ paddingRight: 10 }}>Upload Image</p>
                            <input
                              type="file"
                              style={{
                                position: "relative",
                                visibility: "hidden",
                                opacity: 1,
                                flex: 1
                              }}
                              ref={ref => (this.myInput = ref)}
                              onChange={event => {
                                this.addImage(event);
                              }}
                            />
                            <Button
                              className="btn-round"
                              style={{ float: "right" }}
                              color="primary"
                              type="submit"
                              onClick={() => {
                                this.myInput.click();
                              }}
                            >
                              Browse
                            </Button>
                          </div>

                          {this.state.headers[0].background ? (
                            <img
                              src={this.state.headers[0].background}
                              style={{
                                margin: 5,
                                width: 75,
                                height: 75,
                                float: "right"
                              }}
                            />
                          ) : null}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button
                    className="btn-round"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    type="submit"
                    onClick={() => {
                      this.addEvent();
                      console.log("Events Obj:", this.state.headers);
                    }}
                  >
                    Add New Event
                  </Button>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Events</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Card className="card-plain">
                          <CardBody>
                            <Table>
                              <thead className="text-primary">
                                <tr>
                                  <th>Title</th>
                                  <th>Subtitle</th>
                                  <th>Brief</th>
                                  <th>URL</th>
                                  <th>Image</th>
                                  <th className="text-right"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* {this.props.events.map((event, index) => { */}
                                {this.state.events.map((event, index) => {
                                  return (
                                    <tr>
                                      <td>{event.name}</td>
                                      <td>{event.title}</td>
                                      <td>{event.info}</td>
                                      <td>{event.url}</td>
                                      <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          window.open(
                                            event.background,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <img
                                          src={event.background}
                                          style={{ height: 100 }}
                                        />
                                      </td>

                                      <td>
                                        <Button
                                          className="btn-round"
                                          style={{
                                            marginBottom: 20,
                                            marginLeft: 20
                                          }}
                                          color="primary"
                                          type="submit"
                                          onClick={() => {
                                            this.deleteEvent(event);
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </td>
                                      <td>
                                        <Button
                                          className="btn-round"
                                          style={{
                                            marginBottom: 20,
                                            marginLeft: 20,
                                            paddingLeft: 10,
                                            paddingRight: 10
                                          }}
                                          color="primary"
                                          type="submit"
                                          onClick={() => {
                                            this.moveUp(index);
                                          }}
                                          disabled={index === 0 ? true : false}
                                        >
                                          ↑
                                        </Button>
                                        <Button
                                          className="btn-round"
                                          style={{
                                            marginBottom: 20,
                                            marginLeft: 20,
                                            paddingLeft: 10,
                                            paddingRight: 10
                                          }}
                                          color="primary"
                                          type="submit"
                                          onClick={() => {
                                            this.moveDown(index);
                                          }}
                                          disabled={
                                            index ===
                                            this.state.events.length - 1
                                              ? true
                                              : false
                                          }
                                        >
                                          ↓
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </CardBody>
                        </Card>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}
        </div>
      </>
    );
  }
}
