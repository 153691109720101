import firebase from "firebase";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCKRFvzLvAc0Asb6aAa0ox3gg3krdZeNUY",
  authDomain: "donuts-bitrupt.firebaseapp.com",
  databaseURL: "https://donuts-bitrupt.firebaseio.com",
  projectId: "donuts-bitrupt",
  storageBucket: "donuts-bitrupt.appspot.com",
  messagingSenderId: "1051741669283",
  appId: "1:1051741669283:web:530ef80c109f4c8c0a3c16",
  measurementId: "G-56CFH8DMKJ"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
