/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  UncontrolledAlert,
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input
} from "reactstrap";
import axios from "axios";
import firebase from 'firebase.config'

class Notifications extends React.Component {
  state = {
    title: "",
    info: "",
    url: ""
  };

  handleChange = (event, key) => {
    this.setState({
      [key]: event.target.value
    });
  };

  sendNotification = () => {
    if (this.state.info.length <= 1 || this.state.title.length <= 1) {
      alert("Please enter valid details");
      return;
    }

    firebase
      .database()
      .ref("/groupsData/" + this.props.userDetails.groupPath + "/notificationHistory")
      .push({
        title: this.state.title,
        content: this.state.info,
        timestamp: new Date().getTime()
      })
    let data = {
      app_id: "c6a3bd46-f953-486a-998c-c91905b5ea28",
      contents: { en: this.state.info },
      headings: { en: this.state.title },
      // "subtitle": { "en": "original.subtitle" },
      url: this.state.url.length > 0 ? this.state.url : null,
      filters: [
        {
          field: "tag",
          key: "channel",
          relation: "=",
          value: this.props.userDetails.groupPath
        }
      ]
    };



    let data2 = {
      app_id: "1eb9b91b-f17f-415d-86c5-9ec088392155",
      contents: { en: this.state.info },
      headings: { en: this.state.title },
      // "subtitle": { "en": "original.subtitle" },
      url: this.state.url.length > 0 ? this.state.url : null,
      filters: [
        {
          field: "tag",
          key: "channel",
          relation: "=",
          value: this.props.userDetails.groupPath
        }
      ]
    };



    axios
      .post("https://onesignal.com/api/v1/notifications", data, {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization:
            "Basic YjRjMjE4MjMtZGNkYy00ZDliLWE3OGYtN2NlMWVmOGViNDcx"
        }
      })
      .then(res => {
        this.setState({
          title: "",
          info: "",
          url: ""

        });
        alert("Notification sent successfully");

      })
      .catch(err => {
        alert(
          "There was an error sending notification, please try again later."
        );
      });


    axios
      .post("https://onesignal.com/api/v1/notifications", data2, {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization:
            "Basic MmUzMTFlOGYtODU1MS00NmM1LTg5OWYtODFkZTlkNjdkMThl"
        }
      })
  };

  render() {
    return (
      <>
        <div className="content">
          <NotificationAlert ref={this.notificationAlert} />
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Push Notification</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Push Notification Details: </label>
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex"
                          }}
                        >
                          <Input
                            type="text"
                            placeholder="Enter Notification Title"
                            value={this.state.title}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleChange(event, "title");
                            }}
                          />
                        </div>


                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex"
                          }}
                        >
                          <Input
                            type="text"
                            placeholder="Enter Notification URL(Optional)"
                            value={this.state.url}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleChange(event, "url");
                            }}
                          />
                        </div>


                        <Input
                          type="textarea"
                          placeholder="Enter Content"
                          value={this.state.info}
                          style={{ margin: 5 }}
                          onChange={event => {
                            this.handleChange(event, "info");
                          }}
                        />

                        {/* <Input
                            type="text"
                            placeholder="Enter Background Image URL"
                            value={this.state.background}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleChange(event, "background");
                            }}
                          /> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button
                    className="btn-round"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    type="submit"
                    onClick={() => {
                      this.sendNotification();
                      // console.log("Events Obj:", this.state.headers);
                      // console.log("Props Obj:", this.props);
                    }}
                  >
                    Send Notification
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Notifications;
