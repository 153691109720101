import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  CustomInput,
  Table,
} from "reactstrap";

import firebase from "firebase.config";
import Page4 from "./Page4";
import Page5 from "./Page5";

export default class GroupDetails extends React.Component {
  state = {
    name: "",
    image: "",
    uploading: false,

    page1: [
      {
        title: "",
        url: "",
      },
    ],

    page2: [
      {
        title: "",
        url: "",
      },
    ],

    // page4: [
    //   {
    //     title: "",
    //     url: "",
    //   },
    // ],

    // page5Title: "",
    // page5: [
    //   {
    //     image: null,
    //     title: "",
    //     buttons: [
    //       {
    //         image: null,
    //         title: "",
    //         htmlData: "",
    //       },
    //     ],
    //   },
    // ],

    page3Title: "",
    page3: [
      {
        image: null,
        title: "",
        buttons: [
          {
            image: null,
            title: "",
            htmlData: "",
          },
        ],
      },
    ],
  };

  componentDidMount = () => {
    this.initialize();
  };

  initialize = () => {
    if (this.props.groupData.pageNames) {
      // Page 1 Title
      var temp = this.state.page1;
      temp[0].title = this.props.groupData.pageNames.page1;
      this.setState({ page1: temp });

      // Page 4 Title
      // var temp = this.state.page4;
      // temp[0].title = this.props.groupData.pageNames.page4;
      // this.setState({ page4: temp });

      // Page 2 Title
      var temp = this.state.page2;
      temp[0].title = this.props.groupData.pageNames.page2;
      this.setState({ page2: temp });

      // Page 5 Title
      this.setState({
        // page5Title: this.props.groupData.pageNames.page5,
        page3Title: this.props.groupData.pageNames.page3,
      });
    }

    // Page 1/2 URL
    if (this.props.groupData) {
      var temp = this.state.page1;
      var temp2 = this.state.page2;

      // temp[0].url = this.props.groupData.url;
      // temp[0].url = this.props.groupData.youtubeUrl;
      temp[0].url = this.props.groupData.youtubeUrl1;
      temp2[0].url = this.props.groupData.youtubeUrl2;

      this.setState({
        // page4: temp,
        page1: temp,
        page2: temp2,
      });
    }

    console.log("Group Details Global Page 1", this.props.groupData);
    console.log("Group Details Local Page 1", this.state.page1);

    console.log("Group Details Global Page 2", this.props.groupData);
    console.log("Group Details Local Page 2", this.state.page2);

    console.log("Group Details Global Page 3", this.props.groupData);
    console.log("Group Details Local Page 3", this.state.page3);

    // Page 5 Data
    // if (this.props.groupData.page5) {
    //   var temp = this.state.page5;

    //   temp[0].image = this.props.groupData.page5.image;
    //   temp[0].title = this.props.groupData.page5.title;
    //   temp[0].buttons = this.props.groupData.page5.buttons;

    //   this.setState({
    //     page5: temp,
    //   });
    // }

    // Page 3 Data
    if (this.props.groupData.page3) {
      var temp = this.state.page3;

      temp[0].image = this.props.groupData.page3.image;
      temp[0].title = this.props.groupData.page3.title;
      temp[0].buttons = this.props.groupData.page3.buttons;

      this.setState({
        page3: temp,
      });
    }
  };

  handleChange = (event, key) => {
    this.setState({
      [key]: event.target.value,
    });
  };

  // *************************
  //   PUSH DATA TO DATABASE
  // *************************

  pushPageNamesToDB = async () => {
    await firebase
      .database()
      .ref("groupsData/" + this.props.userDetails.groupPath + "/pageNames/")
      .update({
        page1: this.state.page1[0].title,
        // page4: this.state.page4[0].title,
        page2: this.state.page2[0].title,
        // page5: this.state.page5Title,
        page3: this.state.page3Title,
      });
  };

  pushPage1DataToDB = async () => {
    await firebase
      .database()
      .ref("groupsData/" + this.props.userDetails.groupPath)
      .update({
        youtubeUrl1: this.state.page1[0].url,
      });
  };

  pushPage2DataToDB = async () => {
    await firebase
      .database()
      .ref("groupsData/" + this.props.userDetails.groupPath)
      .update({
        youtubeUrl2: this.state.page2[0].url,
      });
  };

  pushPage3DataToDB = async () => {
    await firebase
      .database()
      .ref("groupsData/" + this.props.userDetails.groupPath)
      .update({
        page3: this.state.page3[0],
      });
  };

  // pushPage5DataToDB = async () => {
  //   await firebase
  //     .database()
  //     .ref("groupsData/" + this.props.userDetails.groupPath)
  //     .update({
  //       page5: this.state.page5[0],
  //     });
  // };

  updateDetails = () => {
    let { name } = this.state;
    if (name.length === 0) {
      name = this.props.groupData.name;
    }
    if (name.length < 2) {
      alert("Please enter valid group name");
      return;
    }

    firebase
      .database()
      .ref("/groupsData/" + this.props.userDetails.groupPath)
      .update({
        name,
        image: this.state.image ? this.state.image : this.props.groupData.image,
      })
      .then((res) => {
        this.setState({
          name: "",
        });
        alert("Updated successfully");
      });

    this.pushPage1DataToDB();
    this.pushPage2DataToDB();
    this.pushPage3DataToDB();
    // this.pushPage5DataToDB();
    this.pushPageNamesToDB();
  };

  saveImage = async (event) => {
    this.setState({
      uploading: true,
    });
    let file = event.target.files[0];

    if (file) {
      var filename = this.props.userDetails.group + "-logo";

      const storage = firebase.storage().ref();
      const image = storage.child(filename);

      await image.put(file).then(async (snapshot) => {
        await image.getDownloadURL().then(async (url) => {
          this.setState({
            image: url,
            uploading: false,
          });
        });
      });
      console.log("Image Uploaded!!!");
    }
  };

  setPageNames = (event, index) => {
    var temp = this.state.pageNames;

    temp[index] = event.target.value;

    this.setState({
      pageNames: temp,
    });
  };

  // ****************************
  //   PAGE 4 ONCHANGE FUNCTION
  // ****************************

  handleURLChange = (value, page) => {
    if (page === 1) {
      let temp = this.state.page1;

      temp[0].url = value;

      this.setState({
        page1: temp,
      });
    } else {
      let temp = this.state.page2;

      temp[0].url = value;

      this.setState({
        page2: temp,
      });
    }

    // temp[0].url = value;

    // this.setState({
    //   page4: temp,
    // });
    // console.log("URL:", this.state.page4);
  };

  handlePage4TitleChange = (value, page) => {
    if (page === 1) {
      let temp = this.state.page1;

      temp[0].title = value;

      this.setState({
        page1: temp,
      });
    } else {
      let temp = this.state.page2;

      temp[0].title = value;

      this.setState({
        page2: temp,
      });
    }
    // let temp = this.state.page4;

    // temp[0].title = value;

    // this.setState({
    //   page4: temp,
    // });
    // console.log("Title:", this.state.page4);
  };

  // ****************************
  //   PAGE 5 ONCHANGE FUNCTION
  // ****************************

  // handlePage5TitleChange = (value) => {
  //   this.setState({
  //     page5Title: value,
  //   });
  // };

  handlePage3TitleChange = (value) => {
    this.setState({
      page3Title: value,
    });
  };

  addHeader = () => {
    var temp = this.state.page3;

    temp[0].buttons.push({
      image: null,
      title: "",
      htmlData: "",
    });

    this.setState({
      page3: temp,
    });
  };

  handleTitleChange = (value, index) => {
    let temp = this.state.page3;
    console.warn(value);
    temp[0].buttons[index].title = value;

    this.setState({
      page3: temp,
    });
  };

  handleEditorChange = (value, index) => {
    let items = this.state.page3;

    console.log("HTML Data: ", items[0].items[index]);
    items[0].buttons[index].htmlData = value;

    this.setState({
      page3: items,
    });
  };

  handleEditorUpdate = (val, index) => {
    let items = this.state.page3;

    // items[0].buttons[index].htmlData = val;
    items[0].buttons[index].htmlData = val;

    this.setState({
      page3: items,
    });
  };

  // addHeader = () => {
  //   var temp = this.state.page5;

  //   temp[0].buttons.push({
  //     image: null,
  //     title: "",
  //     htmlData: "",
  //   });

  //   this.setState({
  //     page5: temp,
  //   });
  // };

  // handleTitleChange = (value, index) => {
  //   let temp = this.state.page5;
  //   console.warn(value);
  //   temp[0].buttons[index].title = value;

  //   this.setState({
  //     page5: temp,
  //   });
  // };

  // handleEditorChange = (value, index) => {
  //   let items = this.state.page5;

  //   console.log("HTML Data: ", items[0].items[index]);
  //   items[0].buttons[index].htmlData = value;

  //   this.setState({
  //     page5: items,
  //   });
  // };

  // handleEditorUpdate = (val, index) => {
  //   let items = this.state.page5;

  //   // items[0].buttons[index].htmlData = val;
  //   items[0].buttons[index].htmlData = val;

  //   this.setState({
  //     page5: items,
  //   });
  // };

  render() {
    return (
      <>
        <div className="content">
          {!this.props.userDetails.group ? (
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Error</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <p>
                        Dear {this.props.userDetails.name}, before you can
                        update group details please set Unique Identifier for
                        your group from the dashboard.
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5">Group Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <Input
                              type="text"
                              placeholder="Enter Group ID"
                              value={this.props.userDetails.group}
                              style={{ margin: 5 }}
                              disabled={true}
                              onChange={(event) => {
                                this.handleChange(event, "name");
                              }}
                            />
                            <Input
                              type="text"
                              placeholder="Enter Group Name"
                              defaultValue={
                                this.props.groupData
                                  ? this.props.groupData.name
                                  : ""
                              }
                              style={{ margin: 5 }}
                              onChange={(event) => {
                                this.handleChange(event, "name");
                              }}
                            />
                          </div>
                          <div
                            className="form-control"
                            style={{
                              margin: 5,
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Input
                              type="file"
                              style={{
                                //   fontSize: 18,
                                //   visibility: "hidden",
                                position: "relative",
                                opacity: 1,
                                flexDirection: "row",
                                display: "flex",
                              }}
                              onChange={(event) => {
                                this.saveImage(event);
                              }}
                            />
                            {this.props.groupData.image || this.state.image ? (
                              <img
                                style={{
                                  height: 70,
                                  width: "auto",
                                  borderRadius: 10,
                                }}
                                src={
                                  this.state.image
                                    ? this.state.image
                                    : this.props.groupData.image
                                }
                              />
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Button
                      className="btn-round"
                      style={{ marginBottom: 20 }}
                      color="primary"
                      type="submit"
                      onClick={() => {
                        this.updateDetails();
                      }}
                      disabled={this.state.uploading}
                    >
                      Update Group Details
                    </Button> */}
                  </CardBody>
                </Card>
              </Col>

              {/* Page 1 */}
              <div>
                <Page4
                  page={1}
                  obj={this.state.page1}
                  onUrlChange={(value, page) => {
                    this.handleURLChange(value, page);
                  }}
                  onTitleChange={(value, page) => {
                    this.handlePage4TitleChange(value, page);
                  }}
                />
              </div>

              {/* Page 2 */}
              <div>
                <Page4
                  obj={this.state.page2}
                  onUrlChange={(value) => {
                    this.handleURLChange(value);
                  }}
                  onTitleChange={(value) => {
                    this.handlePage4TitleChange(value);
                  }}
                />
              </div>

              {/* Page 3 */}
              <div>
                <Page5
                  obj={this.state.page3}
                  key={this.state.page3}
                  title={this.state.page3Title}
                  onTitleChange={(value) => {
                    // this.handlePage5TitleChange(value);
                    this.handlePage3TitleChange(value);
                  }}
                  onAddHeader={() => {
                    this.addHeader();
                  }}
                  onHandleTitleChange={(value, index) => {
                    this.handleTitleChange(value, index);
                  }}
                  onHandleEditorChange={(value, index) => {
                    this.handleEditorChange(value, index);
                  }}
                  onHandleEditorUpdate={(value, index) => {
                    this.handleEditorUpdate(value, index);
                  }}
                />
              </div>

              <Button
                className="btn-round"
                style={{ marginBottom: 20, marginRight: 15, float: "right" }}
                color="primary"
                type="submit"
                onClick={() => {
                  this.updateDetails();
                }}
                disabled={this.state.uploading}
              >
                Update Group Details
              </Button>
            </>
          )}
        </div>
      </>
    );
  }
}
