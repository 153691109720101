import React from "react";
import "./admin.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Table,
} from "reactstrap";
import firebase from "firebase.config";
import axios from "axios";
import { GoogleComponent } from "react-google-location";

const API_KEY = "AIzaSyDQowZmvXDaC1fugjW2xkqg1jZOIVyeapE";



class Admins extends React.Component {


  state = {
    lat: "",
    lng: "",
    name: "",
    pass: "",
    email: "",
    groupId: "",
    address: "",
    place: null,
    markers: [],
    uploadMarker: false,
    selectMarker: false,
    selectedMarker: null,
    iconSize: 40,
    isExistingMarker: false,
    selectedShop: null,
    sortBy: "",
    sortDirection: "desc"
  };

  componentDidMount = () => {
    this.inputFileRef = React.createRef();

    this.getMarkers();
  };

  getMarkers = () => {
    firebase
      .database()
      .ref("/markers/")
      .on("value", (e) => {
        let markers = Object.keys(e.val()).map((key) => { return { ...(e.val()[key]), key: key } });
        console.warn(markers)
        this.setState({ markers });
      });
  };

  disableAccount = (acc) => {
    firebase
      .database()
      .ref("/employees/" + acc.key)
      .update({ disabled: !acc.disabled });
  };

  deleteAccount = (acc) => {
    let res = window.confirm("Are you sure that you want to delete");
    if (res) {
      firebase
        .database()
        .ref("/employees/" + acc.key)
        .update({ deleted: true });
    }
  };

  createAccount = () => {
    const {
      name,
      email,
      pass,
      groupId,
      lat,
      address,
      lng,
      selectedMarker,
      iconSize
    } = this.state;
    if (
      name.length < 2 ||
      email.length < 5 ||
      pass.length < 6 ||
      groupId.length < 2
    ) {
      alert("Please enter valid data");
      return;
    } else {
      fetch(
        "https://us-central1-donuts-bitrupt.cloudfunctions.net/createAdmin", {
        method: "POST",
        // headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name,
          email,
          pass,
          address,
          groupId,
          latitude: lat ? lat : null,
          longitude: lng ? lng : null,
          selectedMarker: selectedMarker ? selectedMarker : null,
          markerSize: iconSize ? iconSize : null,
          existingMarker: this.state.isExistingMarker
        }),
      }
      )
        .then((response) => response.json())
        .then((resp) =>
          this.setState({
            lat: "",
            lng: "",
            name: "",
            pass: "",
            email: "",
            groupId: "",
            address: "",
            uploadMarker: false,
            selectMarker: false,
            selectedMarker: null,
            iconSize: 40,
            isExistingMarker: false
          })
        )
        .catch((err) => {
          alert(
            "There was an error creating admin,please check that there is not group with the same ID and try again later"
          );
          console.warn(err);
        });
    }
  };

  deleteConfirmation = (uid) => {
    let res = window.confirm(
      "Are you sure that you want to delete this account? All newsletters linked with this account will also be deleted"
    );

    if (res) {
      fetch(
        "https://us-central1-donuts-bitrupt.cloudfunctions.net/deleteAdmin",
        { method: "POST", body: JSON.stringify({ uid: uid }) }
      )
        .then((response) => response.json())
        .then((resp) => { })
        .catch((err) => {
          alert("There was an error deleting admin,please try again later");
          console.warn(err);
        });
    }
  };

  getCordinates = () => {
    let place = this.state.place;
    if (place) {
      let cordinates = place.coordinates;
      let location = place.place;
      console.log("Admins -> getCordinates -> place", location);
      let lat = cordinates.lat;
      let lng = cordinates.lng;
      this.setState({ lat, lng, address: location });
    } else {
      this.getCordinates();
    }
  };

  uploadMarker = () => {
    this.setState({ uploadMarker: true, selectMarker: false });
  };

  selectMarker = () => {
    this.setState({ uploadMarker: false, selectMarker: true });
  };

  uploadMarkerIcon = (e) => {
    let image = "";
    alert("image is uploading");
    image = e.target.files[0];
    let imgRef = firebase.storage().ref(image.name);
    imgRef.put(image).then((snapshot) => {
      imgRef.getDownloadURL().then((url) => {
        this.setState({ selectedMarker: url });
        alert("Successful Uploaded");
      });
    });
  };

  selectIcon = (e) => {
    this.setState({ selectedMarker: e.target.src, isExistingMarker: true }, () => {
      console.warn(this.state.selectedMarker)
    });
  };


  updateImage = (acc) => {
    console.warn(acc)
    this.setState({
      selectedShop: acc
    })

    this.inputFileRef.current.click();
  }

  updateMarkerIcon = (e) => {
    if (!this.state.selectedShop) {
      return
    }

    let image = "";
    alert("image is uploading");
    image = e.target.files[0];
    let imgRef = firebase.storage().ref(image.name + new Date().getTime().toString());
    imgRef.put(image).then((snapshot) => {
      imgRef.getDownloadURL().then((url) => {
        firebase.database().ref('/admin/' + this.state.selectedShop.key).update({
          marker: url
        })
        if (this.state.selectedShop.latitude) {
          let latitude = this.state.selectedShop.latitude ? this.state.selectedShop.latitude.toString() : ""
          let longitude = this.state.selectedShop.longitude ? this.state.selectedShop.longitude.toString() : ""
          console.warn(latitude, longitude)
          let coordinateKey = latitude.split(".").join("-") + "|" + longitude.split(".").join("-")
          console.warn(coordinateKey)
          firebase.database().ref('/geolocation/' + coordinateKey).update({
            marker: url
          })
          firebase.database().ref('/markers').push({
            url: url
          })
        }


      });
    });

  }

  uploadMarkerIconDirect = (e) => {
    let image = "";
    image = e.target.files[0];
    let imgRef = firebase.storage().ref(image.name + new Date().getTime().toString());
    imgRef.put(image).then((snapshot) => {
      imgRef.getDownloadURL().then((url) => {
        firebase.database().ref('/markers').push({
          url: url
        })
      });
    });
  }


  compare = (a, b) => {
    if (this.state.sortBy === "name") {
      if (this.state.sortDirection === "asc") {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
      }
      else {
        if (a.name > b.name) return -1;
        if (b.name > a.name) return 1;
      }


      return 0;
    }
    else if (this.state.sortBy === "email") {
      if (this.state.sortDirection === "asc") {
        if (a.email > b.email) return 1;
        if (b.email > a.email) return -1;
      }
      else {
        if (a.email > b.email) return -1;
        if (b.email > a.email) return 1;
      }
      return 0;
    }
    else if (this.state.sortBy === "group") {
      if (this.state.sortDirection === "asc") {

        if (a.group > b.group) return 1;
        if (b.group > a.group) return -1;
      }
      else {
        if (a.group > b.group) return -1;
        if (b.group > a.group) return 1;
      }

      return 0;
    }
    else if (this.state.sortBy === "timestamp") {
      if (this.state.sortDirection === "asc") {
        if (a.timestamp > b.timestamp) return 1;
        if (b.timestamp > a.timestamp) return -1;
      }
      else {
        if (a.timestamp > b.timestamp) return -1;
        if (b.timestamp > a.timestamp) return 1;
      }


      return 0;
    }
    else {
      return 0;
    }

  }


  deleteMarker = (e) => {
    console.warn(e)
    firebase.database().ref('/markers/' + e.key).remove()
  }

  render() {
    return (
      <>
        <div className="content">
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Create New Admin</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>New Admin Details: </label>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <Input
                          placeholder="Enter Name"
                          style={{ margin: 5 }}
                          value={this.state.name}
                          onChange={(event) => {
                            console.warn(event.target.value);
                            this.setState({
                              name: event.target.value,
                            });
                          }}
                          required
                        />

                        <Input
                          placeholder="Enter Unique Group ID"
                          style={{ margin: 5 }}
                          required
                          value={this.state.groupId}
                          onChange={(event) => {
                            console.warn(event.target.value);
                            this.setState({
                              groupId: event.target.value,
                            });
                          }}
                        />
                      </div>

                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <Input
                          placeholder="Enter Email"
                          style={{ margin: 5 }}
                          required
                          value={this.state.email}
                          onChange={(event) =>
                            this.setState({ email: event.target.value })
                          }
                        />
                        <Input
                          placeholder="Enter Password"
                          style={{ margin: 5 }}
                          required
                          value={this.state.pass}
                          onChange={(event) => {
                            console.warn(event.target.value);
                            this.setState({
                              pass: event.target.value,
                            });
                          }}
                        />
                      </div>

                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <div style={{ width: "75%", marginTop: "10px" }}>
                          <GoogleComponent
                            apiKey={API_KEY}
                            language={"en"}
                            locationBoxStyle={"custom-style"}
                            coordinates={true}
                            onChange={(e) => this.setState({ place: e })}
                          />
                        </div>
                        <Button
                          onClick={() => this.getCordinates()}
                          className="btn-round"
                          style={{
                            width: "200px",
                            float: "right",
                            color: "silver",
                            height: "40px",
                            marginLeft: "20px",
                            borderRadius: "5px",
                            border: "1px solid silver",
                            backgroundColor: "white",
                          }}
                          type="submit"
                        >
                          get coordinates
                        </Button>
                      </div>

                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <Input
                          // required
                          placeholder="Latitude"
                          style={{ margin: 5 }}
                          value={this.state.lat}
                          onChange={(event) =>
                            this.setState({ lat: event.target.value })
                          }
                        />
                        <Input
                          placeholder="Longitude"
                          style={{ margin: 5 }}
                          // required
                          value={this.state.lng}
                          onChange={(event) =>
                            this.setState({ lng: event.target.value })
                          }
                        />
                      </div>

                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <Input
                          // required
                          placeholder="Marker Size"
                          style={{ margin: 5 }}
                          value={this.state.iconSize}
                          onChange={(event) =>
                            this.setState({ iconSize: event.target.value })
                          }
                        />
                      </div>

                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <Button
                          onClick={() => this.uploadMarker()}
                          className="btn-round"
                          style={{
                            width: "50%",
                            float: "right",
                            borderRadius: "5px",
                          }}
                          type="submit"
                        >
                          Upload Marker
                        </Button>
                        <Button
                          onClick={() => this.selectMarker()}
                          className="btn-round"
                          style={{
                            width: "50%",
                            float: "right",
                            marginLeft: "10px",
                            borderRadius: "5px",
                          }}
                          type="submit"
                        >
                          select existing marker
                        </Button>
                      </div>

                      {this.state.uploadMarker ? (
                        <div class="custom-file">
                          <input
                            type="file"
                            onChange={this.uploadMarkerIcon}
                            className="custom-file-input"
                            id="customFile"
                          />
                          <label className="custom-file-label" for="customFile">
                            Choose Marker Image...
                          </label>
                        </div>
                      ) : null}

                      <div
                        style={{
                          overflowX: 'auto',
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {this.state.selectMarker &&
                          !!this.state.markers.length &&
                          this.state.markers.map((e) => {
                            return (
                              <img
                                src={e.url}
                                className={
                                  this.state.selectedMarker == e.url
                                    ? "selectedimg"
                                    : "img"
                                }
                                onClick={this.selectIcon}
                              />
                            );
                          })}
                      </div>

                      <Button
                        onClick={() => {
                          this.createAccount();
                        }}
                        className="btn-round"
                        style={{ float: "right" }}
                        color="primary"
                        type="submit"
                      >
                        Create
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
              <div className="update ml-auto mr-auto"></div>
            </Row>
          </Col>







          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Add Marker</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <div class="custom-file">
                      <input
                        type="file"
                        onChange={this.uploadMarkerIconDirect}
                        className="custom-file-input"
                        id="customFile"
                      />
                      <label className="custom-file-label" for="customFile">
                        Choose Marker Image...
                          </label>
                    </div>
                  </Col>
                </Row>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Marker</th>
                      <th className="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!this.state.markers.length &&
                      this.state.markers.map((e) => {
                        return (
                          <tr key={e}>
                            <td>
                              <img
                                src={e.url}
                                className={
                                  this.state.selectedMarker == e.url
                                    ? "selectedimg"
                                    : "img"
                                }
                              />
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  this.deleteMarker(e);
                                }}
                                className="btn-round"
                                style={{ float: "right" }}
                                color="danger"
                                type="submit"
                              >
                                Delete
                                </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

              </CardBody>
            </Card>
            <Row>
              <div className="update ml-auto mr-auto"></div>
            </Row>
          </Col>




          <Row>
            <Col md="12">
              <Card className="card-plain">

                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th></th>
                        <th onClick={() => {
                          if (this.state.sortBy !== "name") {
                            this.setState({ sortBy: 'name', sortDirection: 'asc' })
                          }
                          else {
                            this.setState({ sortBy: 'name', sortDirection: this.state.sortDirection === "asc" ? 'desc' : 'asc' })
                          }
                        }}>Name</th>
                        <th onClick={() => {
                          if (this.state.sortBy !== "email") {
                            this.setState({ sortBy: 'email', sortDirection: 'asc' })
                          }
                          else {
                            this.setState({ sortBy: 'email', sortDirection: this.state.sortDirection === "asc" ? 'desc' : 'asc' })
                          }
                        }}>Email</th>
                        <th onClick={() => {
                          if (this.state.sortBy !== "group") {
                            this.setState({ sortBy: 'group', sortDirection: 'asc' })
                          }
                          else {
                            this.setState({ sortBy: 'group', sortDirection: this.state.sortDirection === "asc" ? 'desc' : 'asc' })
                          }
                        }}>Group</th>
                        <th onClick={() => {
                          if (this.state.sortBy !== "timestamp") {
                            this.setState({ sortBy: 'timestamp', sortDirection: 'asc' })
                          }
                          else {
                            this.setState({ sortBy: 'timestamp', sortDirection: this.state.sortDirection === "asc" ? 'desc' : 'asc' })
                          }
                        }}>Date</th>
                        <th className="text-right"></th>
                      </tr>
                    </thead>
                    <tbody>

                      {this.props.admins.sort(this.compare).map((acc, index) => {

                        if (acc.deleted !== true)
                          return (
                            <tr>
                              <td>
                                <img
                                  onClick={() => { this.updateImage(acc) }}
                                  src={acc.marker} style={{ height: 50, width: 50, cursor: 'pointer' }} />
                              </td>
                              <td>{acc.name}</td>
                              <td>{acc.email}</td>
                              <td>{acc.group}</td>
                              <td>{acc.timestamp ? new Date(acc.timestamp).toDateString() : ""}</td>

                              <td>
                                <Button
                                  onClick={() => {
                                    this.deleteConfirmation(acc.key);
                                  }}
                                  className="btn-round"
                                  style={{ float: "right" }}
                                  color="danger"
                                  type="submit"
                                >
                                  Delete
                                </Button>
                              </td>
                              {/* <td className="text-right">
                                <div className="update ml-auto mr-auto">
                                  <Button
                                    className="btn-round"
                                    color={
                                      acc.disabled === true
                                        ? "primary"
                                        : "success"
                                    }
                                    type="submit"
                                    onClick={() => {
                                      this.disableAccount(acc);
                                    }}
                                  >
                                    {acc.disabled === true
                                      ? "Enable Account"
                                      : "Disable Account"}
                                  </Button>

                                  <Button
                                    className="btn-round"
                                    style={{
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                      marginLeft: 4
                                    }}
                                    color="primary"
                                    type="submit"
                                    onClick={() => {
                                      this.deleteAccount(acc);
                                    }}
                                  >
                                    X
                                  </Button>
                                </div>
                              </td> */}
                            </tr>
                          );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
                <input
                  ref={this.inputFileRef}
                  type="file"
                  onChange={this.updateMarkerIcon}
                  id="customFile"
                  // disabled
                  style={{ width: 1, height: 1, opacity: 0 }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Admins;
